let key = {};
if (process.env.REACT_APP_MODE === "Live") {
  console.log("Set Live Config");
  const API_URL = "https://botapi.arbitribe.com";

  key = {
    API_URL: `${API_URL}`,
    FRONT_URL: "https://bot.arbitribe.com/",
    ADMIN_URL: "https://botcontrols.arbitribe.com",
    getGeoInfo: "https://ipapi.co/json/",
  };
} else if (process.env.REACT_APP_MODE === "demo") {
  console.log("Set Demo Config");
  const API_URL = "https://arbitribeapi.wealwin.com";

  key = {
    API_URL: `${API_URL}`,
    FRONT_URL: "https://arbitribe.wealwin.com/",
    ADMIN_URL: "https://triangular-arbitribe-controls.pages.dev/",
    getGeoInfo: "https://ipapi.co/json/",
  };
} else if (process.env.REACT_APP_MODE === "salesDemo") {
  console.log("Set salesDemo Config");
  const API_URL = "https://previewbotapi.wearedev.team";

  key = {
    API_URL: `${API_URL}`,
    FRONT_URL: "https://previewbot.wearedev.team/",
    ADMIN_URL: "https://preview-bot-controls.pages.dev",
    getGeoInfo: "https://ipapi.co/json/",
  };
} else {
  console.log("Set Development Config");
  const API_URL = "http://localhost";
  key = {
    API_URL: `${API_URL}:2063`,
    getGeoInfo: "https://ipapi.co/json/",
  };
}

export default key;
